<template>
  <div class="product-tag-container">
    <div class="tag" v-for="(option, i) in model" :key="option.id">
      <span>{{ option.name }}</span><fawesome-pro variant="fal" icon="times" class="delete-product" @click.native="removeOutlet(i)" />
    </div>
    <input type="text" v-model="localText" placeholder="Type outlet name" />
    <div class="product-options">
      <div class="row row-cols-2 row-cols-md-5">
        <div class="col" v-for="option in localText ? localOptions.filter(el => new RegExp(localText, 'i').test(el.name)) : localOptions" :key="option.id">
          <label class="m-0" :id="`option-${option.id}`">
            <input type="checkbox" :value="option" v-model="model">
            <span class="pl-1">{{ option.name }}</span>
          </label>
          <b-tooltip v-if="keys" :target="`option-${option.id}`" triggers="hover" noninteractive>
            <div class="text-left" v-for="key in keys" :key="key">
              {{ option[key] }}
            </div>
          </b-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InputMultiBox',
  props: ['value', 'options', 'keys'],
  data() {
    return {
      localOptions: this.options,
      localText: null,
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    removeOutlet(index) {
      this.model.splice(index, 1)
    }
  },
  watch: {
    options(newVal) {
      this.localOptions = newVal
    },
  }
}
</script>
<style scoped>
/* Product tag custom */
.product-tag-container {
  position: relative;
  padding: .5rem;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
.product-tag-container .tag {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: .25rem;
  background: #f2f2f2;
}
.tag .delete-product {
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
}
.product-tag-container input {
  flex-basis: 100%;
  padding: 5px;
  outline: none;
  border: 0;
}
/* Product tag custom validation */
.product-options:hover, .product-tag-container input:focus + .product-options {
  opacity: 1;
  visibility: visible;
}
/* Product options */
.product-options {
  position: absolute;
  border: 1px solid #ced4da;
  border-radius: .5rem;
  background: white;
  overflow: hidden;
  visibility: collapse;
  width: 100%;
  opacity: 0;
  transition: 0s all;
  z-index: 9;
  margin: 0 -8px;
  top: 101%;
}
.product-options > div {
  padding: .5rem;
}
.product-options label {
  cursor: pointer;
}
</style>